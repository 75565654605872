@import "../variables.scss";

.home {
  width: 100%;
  background-color: rgb(31, 31, 31);
  padding-bottom: 5em;
}

.green {
  color: #41f9dd;
}
.navbar {
  height: 80px;
  background-color: rgb(31, 31, 31) !important;
  //   .logo {
  //     height: 80px;
  //     width: auto;
  //   }

  h1 {
    margin: 0 !important;
    padding: 0 !important;
    color: white;
    font-size: 1.2em;
  }
}

.hero-container {
  width: 100%;
  min-height: 100vh;
  color: white;
  padding-top: calc(80px + 2em);

  display: flex;
  flex-direction: row;

  .hero-left {
    width: 50%;
    padding-top: 3em;
    span {
      background: lighten(#41f9dd, 20);
      border: 1px solid #41f9dd;

      color: rgb(31, 31, 31);
      padding: 4px 15px;
      font-weight: 300;
      border-radius: 20px;
      font-size: 0.9em;
    }

    h2 {
      font-size: 4.5em;
      font-weight: 700;
      padding-top: 20px;
    }
    p {
      margin-top: 1.5em;
      color: rgb(212, 212, 212);
      font-size: 1.1em;
    }

    button {
      padding: 10px 30px !important;
      margin-top: 2em;
    }
  }

  .hero-right {
    width: 50%;

    img {
      width: 100%;
    }
  }
}

section.marque {
  background-color: #41f9dd;
  padding-block: 10px;
  .marque-text {
    font-size: 1.5em;
    font-weight: 700;
  }
}

section.benefit {
  color: white;
  min-height: 100vh;
  padding-block: 5em;

  .benefit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .badges {
    background: lighten(#41f9dd, 20);
    border: 1px solid #41f9dd;

    color: rgb(31, 31, 31);
    padding: 4px 15px;
    font-weight: 300;
    border-radius: 20px;
    font-size: 0.9em;
  }

  h2 {
    margin-top: 20px;
    margin-bottom: 0.5em;
  }

  p {
    text-align: center;
    width: 80%;
    color: rgb(200, 200, 200);
  }

  .benefit-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2em;

    .benefit-item {
      width: 32%;
      background-color: #29d5bc0f;
      border: 1px solid #41f9dd;
      border-radius: 15px;
      padding: 2em;

      .icon-wrapper {
        width: 3em;
        height: 3em;
        background-color: #41f9dd;
        border-radius: 50%;
        color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        i {
          font-size: 2em;
        }
      }

      h3 {
        margin-top: 15px;
        margin-bottom: 20px;
        line-height: 1.5em;
        min-height: 3em;
      }

      p {
        text-align: left;
        width: 100%;
      }
    }
  }
}

.cta-container {
  background: rgb(65, 249, 221);
  padding: 2em;
  border-radius: 20px;
  background: linear-gradient(
    90deg,
    rgba(65, 249, 221, 1) 0%,
    rgba(101, 221, 162, 1) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 2.5em;
    font-weight: 700;
    text-align: center;
  }
  p {
    margin: 0;
    text-align: center;
  }

  .cta-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 1em;

    button {
      margin-right: 10px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .navbar .navbar-collapse {
    background-color: rgb(31, 31, 31);
  }
  .hero-container {
    flex-direction: column-reverse;
    margin-bottom: 5em;

    .hero-left {
      width: 100%;
      span {
        background: lighten(#41f9dd, 20);
        border: 1px solid #41f9dd;
      }

      h2 {
        font-size: 3em;
      }
    }

    .hero-right {
      width: 100%;
    }
  }

  section.benefit {
    h2 {
      margin-top: 20px;
      margin-bottom: 0.5em;
    }

    p {
      text-align: center;
      width: 80%;
      color: rgb(200, 200, 200);
    }

    .benefit-list {
      display: flex;
      flex-direction: column;

      .benefit-item {
        width: 100%;
        margin-bottom: 1em;

        h3 {
          min-height: unset;
        }

        p {
          width: 100%;
        }
      }
    }
  }
}

.cta-container {
  .cta-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 1em;

    button {
      margin-right: 10px;
      margin-bottom: 5px;
    }
  }
}
